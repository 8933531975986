export const images = {
  // logo: require("./logo.svg").default,
  logo: require("./zigtech.png"),
  hero: require("./hero.png"),
  intro_desktop: require("./image-intro-desktop.jpg"),
  intro_mobile: require("./image-intro-mobile.jpg"),
  intro_left: require("./bg-pattern-intro-left-desktop.svg").default,
  intro_left_mobile: require("./bg-pattern-intro-left-mobile.svg").default,
  intro_right: require("./bg-pattern-intro-right-desktop.svg").default,
  intro_right_mobile: require("./bg-pattern-intro-right-mobile.svg").default,
  snappy_process: require("./icon-snappy-process.svg").default,
  people_first: require("./icon-people-first.svg").default,
  affordable_prices: require("./icon-affordable-prices.svg").default,
  works_desktop: require("./bg-pattern-how-we-work-desktop.svg").default,
  works_mobile: require("./bg-pattern-how-we-work-mobile.svg").default,
  fb: require("./icon-facebook.svg").default,
  twitter: require("./icon-twitter.svg").default,
  pinterest: require("./icon-pinterest.svg").default,
  insta: require("./icon-instagram.svg").default,
  footer: require("./bg-pattern-footer-desktop.svg").default,
  footer_mob: require("./bg-pattern-footer-mobile.svg").default,
  hamburger: require("./icon-hamburger.svg").default,
  ipay: require("./iPay.png"),
  iremit: require("./iremit.png"),
  imoney: require("./imoney.png"),
  imoney_sm: require("./imoneyNew.jpg"),
  gppa: require("./gppa.jpeg"),
  eden: require("./eden.png"),
  david: require("./dee.jpeg"),
  fatou: require("./fatou.jpeg"),
  omar: require("./doj.jpg"),
  bakary: require("./bakary.jpeg"),
};
